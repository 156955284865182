<template>
  <svg width="304" height="158" viewBox="0 0 304 158" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse opacity="0.15" cx="152" cy="134.339" rx="151.467" ry="23.4667" fill="#B4B4DF" />
    <path d="M43.3837 130.255C42.2503 130.156 41.7332 129.54 41.8323 128.406L42.8457 116.824L7.14203 113.7C6.00858 113.601 5.49144 112.985 5.5906 111.851L6.02754 106.857C6.08952 106.149 6.36092 105.494 6.84174 104.894L51.8147 53.4711C52.3017 52.7999 53.0057 52.5045 53.9266 52.5851L65.509 53.5984C66.6425 53.6976 67.1596 54.3139 67.0604 55.4474L62.9513 102.415L71.9835 103.205C73.117 103.304 73.6341 103.92 73.5349 105.054L72.4286 117.699C72.3295 118.832 71.7132 119.349 70.5797 119.25L61.5475 118.46L60.5342 130.042C60.4351 131.176 59.8187 131.693 58.6853 131.594L43.3837 130.255ZM29.3729 99.4769L44.2494 100.778L46.0623 80.0576L29.3729 99.4769Z" fill="#B9B9E3" />
    <path d="M266.765 134.009C265.635 134.148 265.001 133.653 264.863 132.523L263.446 120.983L227.873 125.351C226.744 125.49 226.11 124.994 225.971 123.865L225.36 118.889C225.273 118.183 225.403 117.487 225.748 116.8L259.047 57.1501C259.384 56.3923 260.011 55.9571 260.928 55.8444L272.468 54.4275C273.598 54.2889 274.232 54.7842 274.37 55.9135L280.116 102.709L289.115 101.604C290.245 101.465 290.879 101.96 291.017 103.09L292.564 115.688C292.703 116.818 292.207 117.452 291.078 117.59L282.079 118.695L283.496 130.235C283.635 131.365 283.139 131.999 282.01 132.137L266.765 134.009ZM246.661 106.817L261.483 104.997L258.948 84.3516L246.661 106.817Z" fill="#B9B9E3" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M153.017 66.2075H91.1504V125.222L91.2291 125.189L153.006 151.541H153.017V66.2075Z" fill="#E1E1F1" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M214.884 66.2075H153.018V151.541H153.186L214.884 125.222V66.2075Z" fill="#D0D0E9" />
    <g filter="url(#filter0_d_705_7134)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M91.1672 66.2084L153.075 92.6225C150.943 95.0484 148.755 97.9886 146.698 101.281C141.987 108.82 139.235 116.163 139.217 120.458L81.0514 93.9282L81.0512 93.9285L89.2509 97.6684L89.2314 97.6906L81.0475 93.9578C80.9206 95.9842 81.0472 97.6812 81.4386 98.9246C80.2109 95.0251 81.5882 86.6651 85.2226 77.6696C87.0045 73.2592 89.0706 69.3356 91.1372 66.2516L91.1549 66.2031L91.1664 66.208L91.1684 66.205L91.1672 66.2084Z" fill="url(#paint0_linear_705_7134)" />
    </g>
    <g filter="url(#filter1_d_705_7134)">
      <path d="M214.99 66.2128L153.082 92.6269C155.214 95.0528 157.401 97.993 159.459 101.285C164.17 108.825 166.922 116.168 166.94 120.462L225.105 93.9326L216.906 97.6729L216.925 97.695L225.109 93.9622C224.838 89.6335 223.41 83.8012 220.934 77.6741C219.152 73.2637 217.086 69.3402 215.02 66.2561L215.002 66.2075L214.99 66.2128Z" fill="url(#paint1_linear_705_7134)" />
    </g>
    <path d="M91.1475 66.2061L153.093 39.7822L215.038 66.2061L153.093 92.6299L91.1475 66.2061Z" fill="url(#paint2_linear_705_7134)" />
    <mask id="mask0_705_7134" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="91" y="23" width="125" height="70">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M214.884 23.3389H91.1509V66.0055H91.6175L91.1475 66.2061L153.093 92.6299L215.038 66.2061L214.568 66.0055H214.884V23.3389Z" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_705_7134)">
      <path d="M139.565 109.419C133.35 107.157 128.552 103.707 125.169 99.0709C121.878 94.3918 120.063 88.7745 119.725 82.219C119.453 75.6879 120.777 68.4129 123.695 60.3942C126.614 52.3755 130.276 45.9518 134.682 41.1231C139.155 36.3188 144.144 33.2157 149.649 31.8139C155.245 30.3696 161.15 30.7784 167.364 33.0403C173.646 35.3265 178.432 38.8092 181.724 43.4882C185.039 48.1004 186.833 53.6721 187.105 60.2033C187.443 66.7588 186.153 74.0459 183.234 82.0646C180.316 90.0833 176.62 96.4949 172.147 101.299C167.741 106.128 162.773 109.277 157.244 110.745C151.739 112.147 145.846 111.705 139.565 109.419ZM146.132 91.3764C149.473 92.5925 152.705 92.0284 155.829 89.6842C159.019 87.3644 161.94 82.5626 164.591 75.2789C167.242 67.9953 168.091 62.4395 167.138 58.6117C166.252 54.8083 164.139 52.2985 160.798 51.0824C157.523 49.8907 154.291 50.4547 151.101 52.7746C147.977 55.1188 145.09 59.9327 142.439 67.2164C139.788 74.5 138.905 80.0436 139.791 83.8471C140.744 87.6749 142.857 90.1847 146.132 91.3764Z" fill="#A7A7D1" />
    </g>
    <g opacity="0.4">
      <rect x="173.873" y="46" width="7.24718" height="7.74564" rx="1.06667" transform="rotate(30 173.873 46)" fill="white" />
      <path d="M177.918 46.6636L176.623 48.6912" stroke="#6B6B96" stroke-width="0.853333" stroke-linecap="round" />
      <path d="M173.839 53.5508L172.543 55.5784" stroke="#6B6B96" stroke-width="0.853333" stroke-linecap="round" />
      <path d="M180.011 53.8257L177.919 52.6379" stroke="#6B6B96" stroke-width="0.853333" stroke-linecap="round" />
      <path d="M172.543 49.7554L170.45 48.5675" stroke="#6B6B96" stroke-width="0.853333" stroke-linecap="round" />
    </g>
    <g opacity="0.4">
      <rect x="273.354" y="107.138" width="7.24718" height="7.74564" rx="1.06667" fill="white" />
      <path d="M277.189 105.69L277.081 108.094" stroke="#6B6B96" stroke-width="0.853333" stroke-linecap="round" />
      <path d="M277.1 113.694L276.991 116.098" stroke="#6B6B96" stroke-width="0.853333" stroke-linecap="round" />
      <path d="M282.583 110.846L280.176 110.864" stroke="#6B6B96" stroke-width="0.853333" stroke-linecap="round" />
      <path d="M274.08 111.055L271.674 111.073" stroke="#6B6B96" stroke-width="0.853333" stroke-linecap="round" />
    </g>
    <g opacity="0.4">
      <rect x="52.1455" y="61.9336" width="7.24718" height="7.74564" rx="1.06667" fill="white" />
      <path d="M55.9815 60.4854L55.8732 62.8893" stroke="#6B6B96" stroke-width="0.853333" stroke-linecap="round" />
      <path d="M55.8917 68.4898L55.7833 70.8937" stroke="#6B6B96" stroke-width="0.853333" stroke-linecap="round" />
      <path d="M61.3747 65.6419L58.9685 65.6595" stroke="#6B6B96" stroke-width="0.853333" stroke-linecap="round" />
      <path d="M52.8717 65.8509L50.4655 65.8685" stroke="#6B6B96" stroke-width="0.853333" stroke-linecap="round" />
    </g>
    <path d="M217.595 55.5191C221.323 55.931 226.281 54.4629 228.65 51.4812C231.018 48.4996 231.196 44.3043 227.943 43.3952C224.689 42.4862 223.02 45.4977 223.356 47.4734C223.692 49.449 226.431 52.5779 231.628 50.6272C236.825 48.6765 237.81 46.4627 238.461 43.142" stroke="#9D9DCB" stroke-width="2.13333" stroke-linecap="round" />
    <defs>
      <filter id="filter0_d_705_7134" x="72.4667" y="59.8031" width="89.1419" height="71.3216" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.13333" />
        <feGaussianBlur stdDeviation="4.26667" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.698039 0 0 0 0 0.698039 0 0 0 0 0.823529 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_705_7134" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_705_7134" result="shape" />
      </filter>
      <filter id="filter1_d_705_7134" x="144.548" y="59.8075" width="89.0945" height="71.3216" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.13333" />
        <feGaussianBlur stdDeviation="4.26667" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.697812 0 0 0 0 0.697812 0 0 0 0 0.825 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_705_7134" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_705_7134" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_705_7134" x1="116.728" y1="75.0719" x2="97.0007" y2="109.216" gradientUnits="userSpaceOnUse">
        <stop stop-color="#C2C2EB" />
        <stop offset="1" stop-color="#E3E3F1" />
      </linearGradient>
      <linearGradient id="paint1_linear_705_7134" x1="184.485" y1="80.4054" x2="201.018" y2="120.405" gradientUnits="userSpaceOnUse">
        <stop stop-color="#C2C2EB" />
        <stop offset="1" stop-color="#E3E3F1" />
      </linearGradient>
      <linearGradient id="paint2_linear_705_7134" x1="155.151" y1="67.6055" x2="152.784" y2="111.181" gradientUnits="userSpaceOnUse">
        <stop stop-color="#BDBDE6" />
        <stop offset="1" stop-color="#8B8BBC" />
      </linearGradient>
    </defs>
  </svg>
</template>
