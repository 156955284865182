<template>
  <svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.15" x="0.500122" y="-0.000244141" width="52" height="52" rx="4" fill="currentcolor" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5001 9.99976C12.291 9.99976 10.5001 11.7906 10.5001 13.9998V22.9998C10.5001 25.2089 12.291 26.9998 14.5001 26.9998H15.4388C15.2036 27.014 14.9724 27.111 14.7926 27.2908L12.6992 29.3842C12.3087 29.7747 12.3087 30.4079 12.6992 30.7984C13.0898 31.1889 13.7229 31.1889 14.1134 30.7984L14.4999 30.4119V31.3146C14.4999 31.8669 14.9477 32.3146 15.4999 32.3146C16.0522 32.3146 16.4999 31.8669 16.4999 31.3146V30.4123L16.8861 30.7984C17.2766 31.1889 17.9097 31.1889 18.3003 30.7984C18.6908 30.4079 18.6908 29.7747 18.3003 29.3842L16.2069 27.2908C16.0271 27.111 15.7959 27.014 15.5607 26.9998H21.5001C23.7092 26.9998 25.5001 25.2089 25.5001 22.9998V13.9998C25.5001 11.7906 23.7092 9.99976 21.5001 9.99976H14.5001ZM19.2458 22.1377V14.865H17.8005L16.0001 16.0049V17.3685L17.6656 16.3245H17.7082V22.1377H19.2458Z" fill="currentcolor" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.5001 24.9998C29.291 24.9998 27.5001 26.7906 27.5001 28.9998V37.9998C27.5001 40.2089 29.291 41.9998 31.5001 41.9998H38.5001C40.7092 41.9998 42.5001 40.2089 42.5001 37.9998V28.9998C42.5001 26.7906 40.7092 24.9998 38.5001 24.9998H31.5001ZM32.4432 36.0784V37.1864H37.6278V35.9293H34.5739V35.8796L35.6357 34.8391C36.1352 34.3845 36.5223 33.9951 36.7969 33.6707C37.0715 33.344 37.2621 33.0469 37.3686 32.7794C37.4775 32.5119 37.532 32.2408 37.532 31.9662C37.532 31.5472 37.4242 31.1755 37.2088 30.8511C36.9934 30.5268 36.6915 30.2735 36.3033 30.0912C35.9174 29.9065 35.464 29.8142 34.9432 29.8142C34.4366 29.8142 33.9891 29.9101 33.6009 30.1019C33.2126 30.2936 32.9108 30.5647 32.6953 30.9151C32.4799 31.2654 32.3722 31.6786 32.3722 32.1544H33.8317C33.8317 31.9224 33.8767 31.7224 33.9666 31.5543C34.0566 31.3862 34.1844 31.2572 34.3501 31.1672C34.5159 31.0772 34.71 31.0322 34.9325 31.0322C35.1456 31.0322 35.3362 31.0749 35.5043 31.1601C35.6724 31.243 35.8049 31.3625 35.902 31.5188C35.9991 31.6726 36.0476 31.8561 36.0476 32.0692C36.0476 32.2609 36.0085 32.4397 35.9304 32.6054C35.8523 32.7688 35.7375 32.9357 35.5859 33.1061C35.4368 33.2766 35.2521 33.4683 35.032 33.6814L32.4432 36.0784Z" fill="currentcolor" />
    <path d="M30.4814 14.9978C31.0337 14.9978 31.4814 14.5501 31.4814 13.9978C31.4814 13.4455 31.0337 12.9978 30.4814 12.9978H28.8399C28.2877 12.9978 27.8399 13.4455 27.8399 13.9978C27.8399 14.5501 28.2877 14.9978 28.8399 14.9978H30.4814Z" fill="currentcolor" />
    <path d="M37.4999 17.5492C36.9477 17.5492 36.4999 17.1015 36.4999 16.5492V14.9978H34.8585C34.3062 14.9978 33.8585 14.5501 33.8585 13.9978C33.8585 13.4455 34.3062 12.9978 34.8585 12.9978H36.4999C37.6045 12.9978 38.4999 13.8932 38.4999 14.9978V16.5492C38.4999 17.1015 38.0522 17.5492 37.4999 17.5492Z" fill="currentcolor" />
    <path d="M38.4999 21.5848V20.6864C38.4999 20.1341 38.0522 19.6864 37.4999 19.6864C36.9477 19.6864 36.4999 20.1341 36.4999 20.6864V21.5852L36.1134 21.1987C35.7229 20.8081 35.0898 20.8081 34.6992 21.1987C34.3087 21.5892 34.3087 22.2224 34.6992 22.6129L36.7926 24.7063C37.1832 25.0968 37.8163 25.0968 38.2069 24.7063L40.3003 22.6129C40.6908 22.2224 40.6908 21.5892 40.3003 21.1987C39.9097 20.8081 39.2766 20.8081 38.8861 21.1987L38.4999 21.5848Z" fill="currentcolor" />
    <path d="M16.4999 35.4518C16.4999 34.8995 16.0522 34.4518 15.4999 34.4518C14.9477 34.4518 14.4999 34.8995 14.4999 35.4518V37.0032C14.4999 38.1078 15.3954 39.0032 16.4999 39.0032H18.1414C18.6937 39.0032 19.1414 38.5555 19.1414 38.0032C19.1414 37.4509 18.6937 37.0032 18.1414 37.0032H16.4999V35.4518Z" fill="currentcolor" />
    <path d="M21.5185 38.0032C21.5185 37.4509 21.9662 37.0032 22.5185 37.0032H24.1599C24.7122 37.0032 25.1599 37.4509 25.1599 38.0032C25.1599 38.5555 24.7122 39.0032 24.1599 39.0032H22.5185C21.9662 39.0032 21.5185 38.5555 21.5185 38.0032Z" fill="currentcolor" />
  </svg>
</template>
